import { observable, action, computed, runInAction, decorate } from 'mobx'
import { uniq, isNil } from 'ramda'

import { me } from 'services/authentification'
import { getDataLocal, setDataLocal, deleteDataLocal } from 'stores/localStorage'
import { emptyDataSession } from 'stores/sessionStorage'
import CommonStore from 'stores/Common/domain/CommonStore'
import PartyInvolvedDBStore from 'stores/Parameters/PartyInvolvedDBStore'
import PartyInvolvedCtrl from 'stores/Common/view/PartyInvolvedCtrl'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import LoginCtrl from 'stores/Common/view/LoginCtrl'
import AlertCtrl from 'stores/Common/view/AlertCtrl'
import Customer from 'stores/Common/domain/Customer'
import InboxStore from 'stores/Messaging/InboxStore'

export const UserRolesExpert = Object.freeze([
  'ROLE_INTERNAL_EXPERT_ADMINISTRATOR',
  'ROLE_INTERNAL_EXPERT',
  'ROLE_ADMIN',
])

export const UserRolesExpertAdmin = Object.freeze([
  'ROLE_INTERNAL_EXPERT_ADMINISTRATOR',
  'ROLE_ADMIN',
])

export const UserRolesInsurer = Object.freeze([
  'ROLE_CLAIM_HANDLER',
  'ROLE_SECRETARY',
  'ROLE_ADMIN',
])

export const UserRolesRepairer = Object.freeze([
  'ROLE_REPAIRER',
  'ROLE_REPAIRER_ADMINISTRATOR',
  'ROLE_ADMIN',
])

export const canAccessUserManagement = Object.freeze([
  'ROLE_CLAIM_HANDLER',
  'ROLE_SECRETARY',
  'ROLE_EXTERNAL_EXPERT_ADMINISTRATOR',
  'ROLE_ADMIN',
  'ROLE_CLAIM_MANAGER_PLUS',
])

export const UserRolesClaimManager = Object.freeze([
  'ROLE_CLAIM_MANAGER',
  'ROLE_CLAIM_MANAGER_PLUS',
  'ROLE_ADMIN',
])

class UserStore {
  id = null
  token = null
  email = null
  refreshToken = null
  expiresIn = null
  firstName = null
  lastName = null
  passwordChangedAt = null
  roles = []
  technicityLevels = []
  mode = null
  customer = null
  impersonating = null
  easyEstimationDefault = false
  logo = null

  loading = true

  constructor() {
    const userInfo = getDataLocal('userInfo')
    const mode = getDataLocal('userMode')

    this.hasRole = this.hasRole.bind(this)

    if (mode) this.mode = mode

    if (userInfo) {
      this.token = userInfo.token
      this.refreshToken = userInfo.refreshToken
      this.expiresIn = userInfo.expiresIn
      this.passwordChangedAt = userInfo.passwordChangedAt
      this.email = userInfo.email
      this.logo = userInfo.logo
    } else {
      this.loading = false
    }
  }

  get rolesAvailable() {
    return this.isClaimManager
      ? uniq([...UserRolesClaimManager])
      : uniq([...UserRolesExpert, ...UserRolesExpertAdmin, ...UserRolesInsurer])
  }

  get defaultMode() {
    let modes = this.modes
    if (!modes.length) return null
    if (modes.includes('insurer')) return 'insurer'

    return modes.shift()
  }

  get modes() {
    let modes = []
    if (this.hasRoleExpert) modes.push('expert')
    if (this.hasRoleInsurer) modes.push('insurer')
    if (this.hasRoleClaimManager) modes.push('manager')

    return modes
  }

  hasMode(mode) {
    return this.modes.includes(mode)
  }

  hasRole(role) {
    return this.roles.includes(role)
  }

  get hasRoleExpert() {
    return this.roles.some(role => ['ROLE_INTERNAL_EXPERT', 'ROLE_EXTERNAL_EXPERT'].includes(role))
  }

  get hasRoleExpertAdmin() {
    return this.roles.some(role => UserRolesExpertAdmin.includes(role))
  }

  get hasRoleRepairer() {
    return this.roles.some(role => UserRolesRepairer.includes(role))
  }

  get hasRoleInsurer() {
    return this.roles.some(role => UserRolesInsurer.includes(role))
  }

  get isExpert() {
    return this.mode === 'expert' && this.hasRoleExpert
  }

  get hasRoleClaimManager() {
    return this.roles.some(role => UserRolesClaimManager.includes(role))
  }

  get isClaimManager() {
    return this.mode === 'manager' && this.hasRoleClaimManager
  }

  get isManager() {
    return (
      this.roles.indexOf('ROLE_CLAIM_HANDLER') > -1 ||
      this.roles.indexOf('ROLE_ADMIN') > -1 ||
      this.roles.indexOf('ROLE_CLAIM_MANAGER_PLUS') > -1
    )
  }
  get isSecretary() {
    return this.roles.indexOf('ROLE_SECRETARY') > -1
  }

  get isInsurer() {
    return this.mode === 'insurer' && this.hasRoleInsurer
  }

  get isAllowedToPartyInvolvedDB() {
    return this.hasRoleExpertAdmin || this.isManager || this.isSecretary
  }

  get isAllowedToUserManagement() {
    return this.roles.some(role => canAccessUserManagement.includes(role))
  }

  get isAllowedToTourConfiguration() {
    return this.roles.some(role => ['ROLE_INTERNAL_EXPERT', 'ROLE_EXTERNAL_EXPERT'].includes(role))
  }

  get isAllowedToCoverageExecutionTime() {
    return this.roles.some(role => ['ROLE_CLAIM_HANDLER', 'ROLE_SECRETARY'].includes(role))
  }

  get isAllowedToImpersonate() {
    return this.roles.includes('ROLE_ADMIN')
  }

  get hasDoubleRoleExpertAndInsurer() {
    return this.hasRoleExpert && this.hasRoleInsurer
  }

  get hasContractEE() {
    return this.customer.hasContract('EE')
  }

  hasContract = contractId => {
    return this.customer.hasContract(contractId)
  }

  loadUserData = async ({ token, refreshToken, expiresIn }) => {
    this.loading = true

    try {
      const res = await me(token)
      runInAction(() => {
        this.customer = new Customer(res.customer)
        if (!this.customer.hasContract('SPS')) {
          AlertCtrl.alert('danger', 'loginPage.contractSPSNotFound')
          this.loading = false
          this.logout(false)
          return
        }
        this.token = token
        this.refreshToken = refreshToken
        this.expiresIn = expiresIn
        this.firstName = res.firstName
        this.lastName = res.lastName
        this.fullName = res.fullName
        this.passwordChangedAt = res.passwordChangedAt
        this.email = res.email
        this.id = res.id
        this.logo = res.logo
        if (this.hasContractEE) {
          this.easyEstimationDefault = res.easyEstimationDefault
        } else {
          this.easyEstimationDefault = false
        }
        setDataLocal('userInfo', {
          token,
          refreshToken,
          expiresIn,
          logo: this.logo,
          passwordChangedAt: this.passwordChangedAt,
          email: this.email,
        })
        if (!res.roles.length) {
          this.logout(false)
          AlertCtrl.alert('danger', 'loginPage.roleNotFound')
        } else {
          // SHOW CHANGE PASSWORD MODAL IF NOT CHANGED
          if (this.passwordChangedAt === null && process.env.REACT_APP_SOLERA_ENV === 'PROD') {
            LoginCtrl.setProperty('modalChangePassword', true)
          }

          this.roles = res.roles
          if (!this.hasMode(this.mode)) this.mode = this.defaultMode
          if (this.isClaimManager && isNil(getDataLocal('firstTimeCalculationBar'))) {
            setDataLocal('firstTimeCalculationBar', true)
            setDataLocal('calculationBarCollapsed', true)
          } else if (this.isClaimManager && Boolean(getDataLocal('firstTimeCalculationBar'))) {
            setDataLocal('calculationBarCollapsed', true)
          }
          setDataLocal('userMode', this.mode)
          PartyInvolvedCtrl.setDefaultCtrl()
          SupportingDocumentsCtrl.setDefaultCtrl()
          CommonStore.loadData()
          PartyInvolvedDBStore.loadData(this.customer.id)
          CommonStore.loadMissionTypes()
          CommonStore.loadCoverages()
          CommonStore.loadInsurers()
          CommonStore.loadExpertiseTypes()
          InboxStore.fetchInbox()
        }
        this.loading = false
      })
    } catch (err) {
      console.error(err)
      runInAction(() => {
        this.logout(false)
        this.loading = false
      })
    }
  }

  updateUser(data) {
    this.firstName = data.firstName
    this.fullName = data.fullName
    this.lastName = data.lastName
    this.roles = data.roles
  }

  logout = (reload = true) => {
    deleteDataLocal('userInfo')
    deleteDataLocal('showOnlyCard')
    LoginCtrl.setProperty('modalChangePassword', false)
    this.token = null
    this.refreshToken = null
    this.expiresIn = null
    this.firstName = null
    this.lastName = null
    this.email = null
    emptyDataSession()
    if (reload) window.location.reload(true)
  }

  setPasswordSetAt(passwordChangedAt) {
    this.passwordChangedAt = passwordChangedAt
    setDataLocal('userInfo', {
      token: this.token,
      refreshToken: this.refreshToken,
      expiresIn: this.expiresIn,
      passwordChangedAt: this.passwordChangedAt,
      email: this.email,
    })
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  // DIRTY HACK for inbox messaging with double role
  // MANAGER seeing his message from expert mode
  quickSwitchMode = mode => {
    if (mode === this.mode) return
    this.mode = mode
    setDataLocal('userMode', this.mode)
    PartyInvolvedCtrl.setDefaultCtrl()
    SupportingDocumentsCtrl.setDefaultCtrl()
  }

  switchMode = mode => {
    if (mode === this.mode) return
    setDataLocal('userMode', mode)
    window.location.href = '/'
  }

  loadImpersonatedUser = () => {
    this.loadUserData({
      token: this.token,
      refreshToken: this.refreshToken,
      expiresIn: this.expiresIn,
    })
  }
}

const DecoratedUserStore = decorate(UserStore, {
  id: observable,
  token: observable,
  refreshToken: observable,
  expiresIn: observable,
  firstName: observable,
  lastName: observable,
  fullName: observable,
  email: observable,
  passwordChangedAt: observable,
  roles: observable,
  technicityLevels: observable,
  mode: observable,
  customer: observable,
  loading: observable,
  impersonating: observable,
  easyEstimationDefault: observable,
  logo: observable,

  switchMode: action,
  setProperty: action,
  setPasswordSetAt: action,
  logout: action,
  loadUserData: action,
  updateUser: action.bound,
  quickSwitchMode: action,
  loadImpersonatedUser: action,
  hasContract: action,

  isAllowedToCoverageExecutionTime: computed,
  isAllowedToTourConfiguration: computed,
  isAllowedToUserManagement: computed,
  isAllowedToPartyInvolvedDB: computed,
  isAllowedToImpersonate: computed,
  isInsurer: computed,
  isManager: computed,
  isSecretary: computed,
  isExpert: computed,
  isClaimManager: computed,
  hasRoleInsurer: computed,
  hasRoleClaimManager: computed,
  hasRoleRepairer: computed,
  hasRoleExpertAdmin: computed,
  hasRoleExpert: computed,
  modes: computed,
  rolesAvailable: computed,
  hasDoubleRoleExpertAndInsurer: computed,
  hasContractEE: computed,
})

export default new DecoratedUserStore()
