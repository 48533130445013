import { path } from 'ramda'
import request from 'services/httpRequest'

export const fetchSinister = async wan => {
  const { data } = await request({
    method: 'GET',
    url: `/claimFiles/v1/claimFileManager/${wan}/static-form/SpsSinister`,
  })
  return data[0].attributes
}

export const saveSinister = async (wan, formData) => {
  try {
    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileManager/${wan}/static-form/SpsSinister`,
      data: {
        data: {
          type: 'SpsSinister',
          attributes: formData,
        },
      },
    })

    return res.data.attributes
  } catch (err) {
    if (err.status !== 500) {
      const errors = []
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      throw errors
    }
    throw err
  }
}

export const fetchInstruction = async wan => {
  const { data } = await request({
    method: 'GET',
    url: `/claimFiles/v1/claimFileManager/${wan}/static-form/SpsInstruction`,
  })
  return data[0].attributes
}

export const saveInstruction = async (wan, formData) => {
  try {
    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileManager/${wan}/static-form/SpsInstruction`,
      data: {
        data: {
          type: 'SpsInstruction',
          attributes: formData,
        },
      },
    })

    return res.data.attributes.irsi
  } catch (err) {
    if (err.status !== 500) {
      const errors = []
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      throw errors
    }
    throw err
  }
}

export const generateReport = async wan => {
  let url = `/claimFiles/v1/claimFileManager/${wan}/download-pdf`

  return await request({
    method: 'get',
    responseType: 'blob',
    url,
  })
}

export const getOrientation = async wan => {
  let url = `/claimFiles/v1/claimFileManager/${wan}/orientation`

  const res = await request({
    method: 'get',
    url,
  })

  return res.data.attributes
}

export const getOrientationForm = async (wan, choice) => {
  let url = `/claimFiles/v1/claimFileManager/${wan}/orientation/form/${choice}`

  const { data } = await request({
    method: 'get',
    url,
  })

  return data[0].attributes
}

export const saveOrientation = async (wan, formData, choice) => {
  const data = {
    data: {
      type: choice,
      attributes: formData,
    },
  }

  try {
    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileManager/${wan}/orientation/form/${choice}`,
      data,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}

export const getPendingForm = async wan => {
  let url = `/claimFiles/v1/claimFileManager/${wan}/static-form/pending`

  const { data } = await request({
    method: 'get',
    url,
  })

  return data[0].attributes
}

export const savePendingForm = async (wan, formData) => {
  const data = {
    data: {
      type: 'pending',
      attributes: formData,
    },
  }

  try {
    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileManager/${wan}/static-form/pending`,
      data,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}

export const closeWithNoFurtherAction = async wan => {
  try {
    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileManager/${wan}/close-with-no-further-action`,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}

export const closeAction = async wan => {
  try {
    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileManager/${wan}/close-claim`,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}

export const getLastBillFirms = async (wan, qualificationCde) => {
  let url = `/claimFiles/v1/claimFileManager/${wan}/last-bill/firms/${qualificationCde}`

  const res = await request({
    method: 'get',
    url,
  })

  const firms = path(['data', 'attributes'], res)

  return firms ? firms : []
}

export const sendJob = async ({ wan, jobId, selectedFirm, comment, dueDate }) => {
  try {
    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileManager/${wan}/last-bill/send/${jobId}`,
      data: {
        data: {
          type: 'sendJob',
          attributes: {
            selectedFirm,
            dueDate,
            comment,
          },
        },
      },
    })

    return res
  } catch (error) {
    throw error
  }
}

export const getLastBillLink = async (wan, jobId) => {
  return await request({
    method: 'get',
    url: `/claimFiles/v1/claimFileManager/${wan}/last-bill/link/${jobId}`,
  })
}
