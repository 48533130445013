import React, { Fragment } from 'react'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import ReportCtrl from 'stores/Mission/view/ReportCtrl'
import Input from 'components/Forms/Input'
import Button from 'components/Button/Button'
import ModalWithBackdrop from 'components/ModalWithBackdrop/ModalWithBackdrop'
import SDModal from 'components/SDModal'
import Form from './Form'
import MessagingStore from 'stores/Messaging/MessagingStore'
import UserStore from 'stores/Common/domain/UserStore'
import classNames from 'classnames'

class EmailModal extends React.Component {
  state = {
    grabbing: false,
  }

  setGrabbing = () => {
    this.setState({ grabbing: !this.state.grabbing })
  }

  toggleModal = () => ReportCtrl.toggleModal()

  render() {
    const { t, claimType } = this.props
    const { id } = this.props.match.params

    return (
      <ModalWithBackdrop
        size={UserStore.hasContract('EM') ? 'modal-lg' : 'modal-sm'}
        show={ReportCtrl.emailModal}
        close={ReportCtrl.closeModal}
        draggable
        scrollable
        setGrabbing={this.setGrabbing}
      >
        <div
          className={classNames('modal-header', {
            grabbable: !this.state.grabbing,
            grabbing: this.state.grabbing,
          })}
        >
          <h5 className="modal-title">{t('mission.report.sendMailTitle')}</h5>
          <button type="button" className="close" onClick={this.toggleModal}>
            <span>&times;</span>
          </button>
        </div>
        <div className="modal-body overflow-x-hidden">
          <Fragment>
            <div className="form-group row no-gutters">
              <Input
                className="form-control"
                name="emailAddress"
                value={ReportCtrl.email}
                onChange={e => ReportCtrl.setEmail(e.target.value)}
                placeholder={t('messaging.message.email')}
                errors={toJS(ReportCtrl.fieldErrors)}
              />
            </div>
            {UserStore.hasContract('EM') && (
              <Fragment>
                <div className="form-group row no-gutters">
                  <Input
                    className="form-control"
                    name="emailSubject"
                    value={ReportCtrl.subject}
                    onChange={e => ReportCtrl.setSubject(e.target.value)}
                    placeholder={t('messaging.message.subject')}
                    errors={toJS(ReportCtrl.fieldErrors)}
                  />
                </div>
                <Form />
                <SDModal />
              </Fragment>
            )}
          </Fragment>
        </div>
        <div className="modal-footer pb-2 pt-2">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => ReportCtrl.toggleModal()}
          >
            {t('partyInvolved.modal.close')}
          </button>
          <Button
            className="btn btn-primary"
            onClick={() =>
              ReportCtrl.validateEmail(
                id,
                claimType,
                ReportCtrl.subject,
                MessagingStore.currentMessage.text,
                MessagingStore.currentMessage.documents,
              )
            }
            disabled={ReportCtrl.loading}
          >
            {t('mission.report.sendAction')}
          </Button>
        </div>
      </ModalWithBackdrop>
    )
  }
}

EmailModal.defaultProps = {
  claimType: 'cfa',
}

export default withRouter(withTranslation()(observer(EmailModal)))
