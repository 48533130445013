import React from 'react'
import { splitAt, splitEvery, sortBy, compose, prop, toLower, isNil, type, path } from 'ramda'
import { format, parseISO } from 'date-fns'
import { parse as parsePhoneNumber, format as formatPhoneNumber } from 'libphonenumber-js'
import { MATMUT_THEME, CARDIF_THEME } from 'utils/constants'

import { getLocale } from 'stores/localStorage'

const dateFnsLocales = {
  en: require('date-fns/locale/en-US'),
  fr: require('date-fns/locale/fr'),
}

export const formatWan = wan => {
  try {
    const splittedWan = splitAt(4, wan)
    const threeDigits = splitEvery(3, splittedWan[1])
    return [splittedWan[0], threeDigits.join(' ')].join(' ')
  } catch (err) {
    console.log('Formatting wan : something went wrong')
    console.log(err)
  }
}

export const formatCustomerId = customerId => {
  // FR10000001I => FR 100000 0 1I
  try {
    if (!customerId || customerId.length !== 11) return customerId

    const country = customerId.substring(0, 2)
    const id = customerId.substring(2, 8)
    const key = customerId[8]
    const suffix = customerId.substring(9, 11)
    return `${country} ${id} ${key} ${suffix}`
  } catch (err) {
    console.log('Formatting customerId : something went wrong')
    console.log(err)
  }
}

export const formatDate = (date, withDay = true) => {
  if (!date) return null
  date = parseISO(date)
  const locale = getLocale() || 'fr-FR'
  const lang = locale[0] + locale[1]
  if (withDay) return format(date, `dd/MM/y (EEEE)`, { locale: dateFnsLocales[lang] })
  return format(date, `dd/MM/y`, { locale: dateFnsLocales[lang] })
}

export const formatDateHour = ({ date, addDayName = true, addAtBetweenDAndH = false }) => {
  if (!date) return '-'

  if (!date.endsWith('Z')) {
    date = date + 'Z'
  }
  const ISOdate = parseISO(date)

  const locale = getLocale() || 'fr-FR'
  const lang = locale[0] + locale[1]
  const dayName = addDayName ? ' (EEEE)' : ''
  const at = addAtBetweenDAndH ? (locale === 'fr-FR' ? '\u00E0 ' : '[at] ') : ''

  return locale === 'fr-FR'
    ? format(ISOdate, `dd/MM/y ${at}HH:mm${dayName}`, { locale: dateFnsLocales[lang] })
    : format(ISOdate, `dd/MM/y ${at}h.mm${'\u00A0'}a${dayName}`, { locale: dateFnsLocales[lang] })
}

export const concatAddress = (addressObj, onlyCityAndZipCode = false) => {
  if (!addressObj) return '-'

  const { streetNumber, addressLine1, addressLine2, addressLine3, zipCode, city } = addressObj

  if (onlyCityAndZipCode) return concatNames(zipCode, '\n', city)

  const addressLines = []

  if (addressLine1 && addressLine1.length > 0) addressLines.push(addressLine1)
  if (addressLine2 && addressLine2.length > 0) addressLines.push(addressLine2)
  if (addressLine3 && addressLine3.length > 0) addressLines.push(addressLine3)

  const fullAddress = [
    `${streetNumber && streetNumber.length > 0 ? `${streetNumber} ` : ''}${addressLines.join(
      ', ',
    )}`,
    `${zipCode && zipCode.length > 0 ? `${zipCode}` : ''}${
      city && city.length > 0 ? ` ${city}` : ''
    }`,
  ]

  return fullAddress.filter(text => text.length > 0).join(', ')
}

export const formatPhone = phoneNumber => {
  if (isNil(phoneNumber)) return ''

  // handle when there is no +33 for french number
  const regexFrenchPhoneNumber = new RegExp(/^(01|02|03|04|05|06|07|08)[0-9]{8}/gi)

  try {
    if (regexFrenchPhoneNumber.test(phoneNumber)) {
      phoneNumber = phoneNumber.replace('0', '+33')
    }
  } catch (err) {
    console.warn(err)
    return phoneNumber
  }

  let res = null

  try {
    res = parsePhoneNumber(phoneNumber)
  } catch (err) {
    console.warn(err)
    return phoneNumber
  }
  if (!res || (!res.phone && !res.country)) {
    return phoneNumber
  }

  return formatPhoneNumber(res, 'National')
}

export const slugify = text => {
  const splittedText = text.toLowerCase().split('.')
  const extension = splittedText.pop()
  const slugifyText = splittedText
    .join('')
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')

  return `${slugifyText}.${extension}`
}

export const uid = (length = 5) => {
  let uid = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < length; i++)
    uid += possible.charAt(Math.floor(Math.random() * possible.length))

  return uid
}

// eslint-disable-next-line no-useless-escape
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const extractSdIdFromUrl = src => {
  const arrOfStr = src.split('/')
  return arrOfStr[5] ? arrOfStr[5] : ''
}

export const formatArea = area => {
  let areaString = area.toString().replace('.', ',')
  const areaSplitted = areaString.split(',')
  if (areaSplitted.length === 2 && areaSplitted[1].length === 1) areaString += '0'

  return areaString
}

export const sortByCaseInsensitive = propName => {
  return sortBy(
    compose(
      toLower(),
      prop(propName),
    ),
  )
}

export const ellipsis = (string, maxLength) => {
  if (string.length < maxLength) return string
  return `${string.substring(0, maxLength)}...`
}

export const concatNames = (...names) => {
  return names
    .filter(name => !!name || name === 0)
    .join(' ')
    .trim()
}

export const noop = () => {}

export const arrayToDivs = arr => {
  const div = (line, i) => (
    <div
      key={concatNames(i, '-', line)}
      className={line && line.startsWith('#') ? 'text-primary' : ''}
    >
      {line}
    </div>
  )

  return arr.length
    ? arr.reduce((acc, cur, i) => (acc ? [...acc, div(cur, i)] : [div(cur, i)]), null)
    : ''
}

export const getTheme = () => {
  const hostName = window.location[process.env.NODE_ENV === 'development' ? 'href' : 'hostname']

  if (MATMUT_THEME.some(matmutHostName => hostName.includes(matmutHostName))) return 'matmut'
  if (CARDIF_THEME.some(cardifHostName => hostName.includes(cardifHostName))) return 'cardif'
  return null
}

export const truncate = (text, max = 80) => {
  return text && text.length > max ? `${text.slice(0, max)}...` : text
}

export const PromiseAllSkipErrors = promises => {
  return Promise.all(promises.map(p => p.catch(() => null)))
}

export const convertUTCDateToLocalDate = date => {
  const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
  const offset = date.getTimezoneOffset() / 60
  const hours = date.getHours()
  newDate.setHours(hours - offset)
  return newDate
}

export const capitalize = string => {
  if (!type(string) === 'String') {
    console.warn(`${string} is not a string.`)
    return ''
  }

  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const screenOrientation = () => {
  return (
    (window.screen.orientation || {}).type ||
    window.screen.mozOrientation ||
    window.screen.msOrientation
  )
}

export const isInspectionMission = claim => {
  if (!claim) return false
  if (claim.tags.indexOf === undefined) return false

  return (
    claim.tags.indexOf('MATMUT_SUIVI_DIVERS') > -1 ||
    claim.tags.indexOf('MATMUT_DIVERS') > -1 ||
    claim.tags.indexOf('MATMUT_SUIVI_CORPORAL') > -1 ||
    claim.tags.indexOf('MATMUT_CORPORAL') > -1
  )
}

export const verifyValue = (overrideValue, value) => (isNil(overrideValue) ? value : overrideValue)

export const getPositionFromAddress = address => {
  const position = path(['geometry'], address)
  if (position && position.lat && position.long) return { lat: position.lat, lng: position.long }

  return null
}
