import request from 'services/httpRequest'

export const fetchClaim = async wan => {
  try {
    const res = await request({
      method: 'get',
      url: `/claimFiles/v1/${wan}`,
    })
    return res.data.attributes
  } catch (error) {
    throw error
  }
}

// not used in the project
export const fetchInternalExpert = wan => {
  return request({
    method: 'get',
    url: `/claimFiles/v1/${wan}/users-search/ROLE_INTERNAL_EXPERT`,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

// not used in the project
export const fetchNetworkExpert = wan => {
  return request({
    method: 'get',
    url: `/claimFiles/v1/${wan}/network/search/ASSESSOR`,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const qualifyMission = async ({ body, wan, missionId }) => {
  const url = `/claimFiles/v1/${wan}/missions-insurer`

  try {
    const { data } = await request({
      method: 'POST',
      url: missionId ? `${url}/${missionId}` : url,
      data: {
        data: {
          type: 'SpsMissionInsurer',
          attributes: body,
        },
      },
    })
    return data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const getClaimForm = async () => {
  const { data } = await request({
    method: 'GET',
    url: '/claimFiles/v1/claimFileAssessor/workCfa',
  })
  return data[0].attributes
}

export const createClaim = async formData => {
  const data = {
    data: {
      type: 'work_cfa',
      attributes: formData,
    },
  }

  try {
    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileAssessor/workCfa`,
      data,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}

export const createManagerClaim = async formData => {
  const data = {
    data: {
      type: 'ClaimFileManager',
      attributes: formData,
    },
  }

  try {
    const res = await request({
      method: 'POST',
      url: `/claimFiles/v1/claimFileManager/`,
      data,
    })
    return res.data.attributes
  } catch (err) {
    const errors = []
    if (err.status !== 500 && err.data) {
      err.data.errors.forEach(error => {
        errors.push({
          key: error.source.pointer.split('/').pop(),
          value: error.detail,
        })
      })
      return Promise.reject(errors)
    }
    return Promise.reject(err)
  }
}

export const getManagerClaimForm = async () => {
  const { data } = await request({
    method: 'GET',
    url: '/claimFiles/v1/claimFileManager/',
  })
  return data[0].attributes
}

export const fetchMotifCancelType = async () => {
  try {
    const { data } = await request({
      method: 'get',
      url: '/businessData/v1/cancellationClosure',
    })
    return data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const cancelMission = async (wan, attributes) => {
  try {
    const { data } = await request({
      method: 'POST',
      url: `/claimFiles/v1/${wan}/close`,
      data: {
        data: {
          type: 'close_cfi',
          attributes,
        },
      },
    })
    return data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const reassignMission = async (wan, attributes) => {
  try {
    const { data } = await request({
      method: 'POST',
      url: `/claimFiles/v1/${wan}/updateCustomer`,
      data: {
        data: {
          type: 'customer',
          attributes,
        },
      },
    })
    return data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const fetchPoles = async () => {
  try {
    const { data } = await request({
      method: 'get',
      url: '/customers/v1/assignableList',
    })
    return data
  } catch (err) {
    return Promise.reject(err)
  }
}

export const changeCurrentMissionAssignee = async (cfiWan, cfaWan, user) => {
  const { data } = await request({
    method: 'PUT',
    url: `/claimFiles/v1/${cfiWan}/reassignment/${cfaWan}`,
    data: {
      data: {
        type: 'reassignment',
        attributes: {
          user,
        },
      },
    },
  })
  return data
}

export const changeToAggravation = async cfaWan => {
  const res = await request({
    method: 'POST',
    url: `/claimFiles/v1/claimFileAssessor/${cfaWan}/create-aggravation-mission`,
  })
  return res
}

export const getApplicableConvention = async wan => {
  const res = await request({
    method: 'GET',
    url: `/convention/v1/getApplicableConventionForCfa/${wan}`,
  })

  return res
}

export const sendConfirmationBySms = async (wan, phoneNumber) => {
  const { confirmDate } = await request({
    method: 'post',
    url: `/claimFiles/v1/claimFileAssessor/${wan}/confirm-by-sms`,
    data: {
      data: {
        type: 'sms',
        attributes: {
          phoneNumber,
        },
      },
    },
  })

  return confirmDate
}
