import React, { Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import { NavLink, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import PendingModal from 'pages/ManagerClaim/pages/Pending/PendingModal'

import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'

const ManagerClaimMobile = ({
  UiCtrl: { toggleSideNav },
  CartStore: { easyEstimation },
  ManagerClaimStore: {
    isClaimClosed,
    isPending,
    closeWithNoFurtherAction,
    closeAction,
    alreadyChosenOrientation,
    hasLastBillJob,
  },
  UserStore: { customer },
}) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const hasDeclarationContract = customer.hasContract('DECLA')

  return (
    <div className="side-nav">
      <h5 className="pt-3  nav-border-bottom">{t('claim.sideNav.title')}</h5>
      {hasDeclarationContract && (
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/managerClaim/${id}/information`}
          onClick={() => toggleSideNav(false)}
        >
          {t('claim.sideNav.information')}
        </NavLink>
      )}

      {hasDeclarationContract && (
        <h5 className="pt-3  nav-border-bottom">{t('mission.sideNav.titleAnnexe')}</h5>
      )}

      {hasDeclarationContract && (
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/managerClaim/${id}/partyInvolved`}
          onClick={() => toggleSideNav(false)}
        >
          {t('mission.sideNav.partyInvolved')}
        </NavLink>
      )}

      {hasDeclarationContract && (
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/managerClaim/${id}/supportingDocuments`}
          onClick={() => toggleSideNav(false)}
        >
          {t('mission.sideNav.supportingDocument')}
        </NavLink>
      )}

      <h5 className="pt-3  nav-border-bottom">{t('mission.sideNav.sinister')}</h5>

      {hasDeclarationContract && (
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/managerClaim/${id}/declaration`}
          onClick={() => toggleSideNav(false)}
        >
          {t('mission.sideNav.sinisterDeclaration')}
        </NavLink>
      )}
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/managerClaim/${id}/calculation`}
        onClick={() => toggleSideNav(false)}
      >
        {t('mission.sideNav.calculation')}
      </NavLink>
      {!easyEstimation && (
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/managerClaim/${id}/ventilation`}
          onClick={() => toggleSideNav(false)}
        >
          {t('mission.sideNav.ventilationREN')}
        </NavLink>
      )}
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/managerClaim/${id}/instruction`}
        onClick={() => toggleSideNav(false)}
      >
        {t('mission.sideNav.indemnisation')}
      </NavLink>

      <h5 className="pt-3 nav-border-bottom">{t('managerClaim.sideNav.compensation')}</h5>
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/managerClaim/${id}/orientation`}
        onClick={() => toggleSideNav(false)}
      >
        {t('managerClaim.sideNav.orientation')}
      </NavLink>
      {alreadyChosenOrientation && (
        <NavLink
          className="nav-link"
          activeClassName="active"
          to={`/managerClaim/${id}/report`}
          onClick={() => toggleSideNav(false)}
        >
          {t('managerClaim.sideNav.report')}
        </NavLink>
      )}

      {alreadyChosenOrientation && hasLastBillJob && (
        <Fragment>
          <h5 className="pt-3  nav-border-bottom">{t('mission.sideNav.titleMission')}</h5>
          <NavLink
            className="nav-link"
            activeClassName="active"
            to={`/managerClaim/${id}/last-bill`}
            onClick={() => toggleSideNav(false)}
          >
            {t('lastbill.title')}
          </NavLink>
        </Fragment>
      )}

      <h5 className="pt-3  nav-border-bottom">{t('mission.sideNav.titleCommunication')}</h5>
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/managerClaim/${id}/messaging`}
        onClick={() => toggleSideNav(false)}
      >
        {t('messaging.title')}
      </NavLink>
      <NavLink
        className="nav-link"
        activeClassName="active"
        to={`/managerClaim/${id}/events`}
        onClick={() => toggleSideNav(false)}
      >
        {t('events.title')}
      </NavLink>

      {!isClaimClosed && !isPending && (
        <div classNameWrapper="btn-block bg-white" className="btn-block">
          {' '}
          <PendingModal />{' '}
        </div>
      )}

      {!isClaimClosed && (
        <ButtonWithConfirmation
          classNameWrapper="btn-block bg-white"
          className="btn btn-outline-primary btn-block"
          onClick={closeWithNoFurtherAction}
          confirmText={t('mission.report.confirmationCloseMission')}
          text={
            <span>
              Clôture sans suite <i className="fa fa-unlock-alt" />
            </span>
          }
        />
      )}

      {!isClaimClosed && (
        <ButtonWithConfirmation
          classNameWrapper="btn-block bg-white"
          className="btn btn-outline-primary btn-block"
          onClick={closeAction}
          confirmText={t('mission.report.confirmationCloseMission')}
          text={
            <span>
              Terminer le dossier <i className="fa fa-unlock-alt" />
            </span>
          }
        />
      )}
    </div>
  )
}

export default inject('UiCtrl', 'CartStore', 'ManagerClaimStore', 'UserStore')(
  observer(ManagerClaimMobile),
)
