import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import classNames from 'classnames'

import './Cart.css'
import AddRelatedCostCtrl from 'stores/Mission/view/AddRelatedCostCtrl'
import PropertyEmbellishmentCtrl from 'stores/Mission/EasyEstimation/PropertyEmbellishmentCtrl'
import CardLayout from 'components/CardLayout/CardLayout'
import FurnitureList from './FurnitureList'
import PropertyList from './PropertyList'
import EmbellishmentList from './EmbellishmentList'
import LeaksList from './LeaksList'
import RelatedCostList from './RelatedCostList'
import OutdoorList from './OutdoorList'
import SelfRepairSlider from './SelfRepairSlider'
import Breadcrumb from 'pages/Mission/pages/Calculation/Breadcrumb/Breadcrumb'
import LastUpdatedAt from 'pages/Mission/components/LastUpdatedAt'
import Loader from 'components/Loader/Loader'
import IRSITabs from './IRSITabs'
import TotalRecap from './TotalRecap'
import ButtonWithConfirmation from 'components/ButtonWithConfirmation/ButtonWithConfirmation'
import SaveEstimation from 'components/SaveEstimation'
import AlertCompensatory from 'pages/ManagerClaim/pages/Instruction/Tables/AlertCompensatory'

const Cart = ({
  MissionStore: {
    isWaterDamage: isMissionWaterDamage,
    isIRSI,
    IRSIInvolvedPartyWithName,
    showComputationWithoutIP,
    isMissionClosed,
  },
  ManagerClaimStore: { isWaterDamage: isManagerClaimWaterDamage, isClaimClosed },
  UserStore: { hasContractEE, hasRoleClaimManager },
  CartStore: {
    loading,
    relatedCostPackages,
    overflowPackages,
    noGuaranteePackages,
    emptyCart,
    isCartEmpty,
    setProperty,
    handleKonamiCode,
  },
}) => {
  const { t } = useTranslation()
  const { type } = useParams()
  const isWaterDamage = type === 'mission' ? isMissionWaterDamage : isManagerClaimWaterDamage
  const isClosed = type === 'mission' ? isMissionClosed : isClaimClosed

  useEffect(() => {
    window.scrollTo(0, 0)
    document.addEventListener('keydown', handleKonamiCode)
    return () => {
      document.removeEventListener('keydown', handleKonamiCode)
    }
    // eslint-disable-next-line
  }, [])
  const { relatedCostType, overflowType, noGuaranteeType } = AddRelatedCostCtrl

  return (
    <React.Fragment>
      <IRSITabs />

      <CardLayout
        className={classNames('cart', {
          'show-card-top':
            !isIRSI || (IRSIInvolvedPartyWithName.length === 0 && !showComputationWithoutIP),
        })}
      >
        <div className="container pl-0 pr-0">
          <SaveEstimation className="btn btn-danger mb-3" />
          {hasContractEE && !isIRSI && (
            <div className="row no-gutters mb-3">
              <div className="col-8">
                {isCartEmpty ? (
                  <button
                    className={classNames('btn btn-primary', { 'not-allowed': isClosed })}
                    onClick={() => {
                      setProperty('easyEstimation', true)
                      emptyCart()
                      PropertyEmbellishmentCtrl.emptyRooms()
                    }}
                    disabled={isClosed}
                  >
                    Passer en chiffrage simplifié
                  </button>
                ) : (
                  <ButtonWithConfirmation
                    className={classNames('btn btn-primary', { 'not-allowed': isClosed })}
                    classNameWrapper="d-inline"
                    text="Passer en chiffrage simplifié"
                    confirmText="En quittant le Mode Chiffrage détaillé, tout le chiffrage sera supprimé. Le confirmez-vous ?"
                    onClick={() => {
                      setProperty('easyEstimation', true)
                      emptyCart()
                      PropertyEmbellishmentCtrl.emptyRooms()
                    }}
                    disabled={isClosed}
                  />
                )}
              </div>
              <div className="col-4">{hasRoleClaimManager && <AlertCompensatory />}</div>
            </div>
          )}
          <div className="row no-gutters">
            <div className="col-12">
              <Breadcrumb />
            </div>
          </div>

          {loading ? (
            <Loader />
          ) : (
            <React.Fragment>
              <PropertyList />

              <EmbellishmentList />

              <FurnitureList />

              <OutdoorList />

              {isWaterDamage && <LeaksList />}

              {typeof relatedCostType.length !== undefined && relatedCostType.length > 0 && (
                <RelatedCostList
                  title={t('mission.calculation.cart.titleRelatedCost')}
                  list={relatedCostPackages}
                  packageType="relatedCost"
                  endpoint="addRelatedCost"
                />
              )}

              {typeof overflowType.length !== undefined && overflowType.length > 0 && (
                <RelatedCostList
                  title={t('mission.calculation.cart.titleOverflow')}
                  list={overflowPackages}
                  packageType="overflow"
                  endpoint="addOverflow"
                />
              )}

              {typeof noGuaranteeType.length !== undefined && noGuaranteeType.length > 0 && (
                <RelatedCostList
                  title={t('mission.calculation.cart.titleNoGuarantee')}
                  list={noGuaranteePackages}
                  packageType="noGuarantee"
                  endpoint="addNoGuarantee"
                />
              )}

              <TotalRecap />
              <SelfRepairSlider key="self-repair-slider" />
              <LastUpdatedAt key="last-updated-at" />
            </React.Fragment>
          )}
        </div>
      </CardLayout>
    </React.Fragment>
  )
}

export default inject('CommonStore', 'MissionStore', 'CartStore', 'UserStore', 'ManagerClaimStore')(
  observer(Cart),
)
