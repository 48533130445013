import { observable, action, computed, decorate, runInAction } from 'mobx'
import { isNil, path } from 'ramda'

import { fetchManagerClaim } from 'services/managerClaim'
import CommonStore from 'stores/Common/domain/CommonStore'
import SupportingDocumentsCtrl from 'stores/Common/view/SupportingDocumentsCtrl'
import SinisterCtrl from 'stores/ManagerClaim/view/SinisterCtrl'
import InstructionCtrl from 'stores/ManagerClaim/view/InstructionCtrl'
import PartyInvolvedStore from 'stores/Common/domain/PartyInvolvedStore'
import SearchFurnitureCtrl from 'stores/Mission/view/SearchFurnitureCtrl'
import SearchPackageCtrl from 'stores/Mission/view/SearchPackageCtrl'
import AddrelatedCostCtrl from 'stores/Mission/view/AddRelatedCostCtrl'
import SearchLeakCtrl from 'stores/Mission/view/SearchLeakCtrl'
import CartStore from 'stores/Mission/domain/CartStore'
import AddFurnitureCtrl from 'stores/Mission/view/AddFurnitureCtrl'
import UserStore from 'stores/Common/domain/UserStore'

import {
  getPendingForm,
  savePendingForm,
  closeWithNoFurtherAction,
  closeAction,
} from 'services/claimManagerForm'
import Form from 'utils/dynform/Form'

class ManagerClaimStore {
  loading = true
  aggravating = false
  reassigning = false
  claim = null
  missionsWan = []
  pendingForm = null
  showPendingModal = false
  showTeleexpertiseForm = false

  id = null

  get status() {
    return path(['status', 'key'], this.claim)
  }

  get isWaterDamage() {
    return path(['claimInformation', 'coverage', 'key'], this.claim) === 'WTDG'
  }

  get isClaimClosed() {
    return (
      path(['status', 'key'], this.claim) === 'CMCC' ||
      path(['status', 'key'], this.claim) === 'CMCA'
    )
  }

  get isReportLinksAvailable() {
    return (
      path(['status', 'key'], this.claim) === 'CMIN' ||
      path(['status', 'key'], this.claim) === 'CMCA' ||
      path(['status', 'key'], this.claim) === 'CMCC' ||
      path(['status', 'key'], this.claim) === 'CMCC'
    )
  }

  get dateOfLoss() {
    return path(['claimInformation', 'dateOfLoss'], this.claim)
  }

  get orientationChoice() {
    return path(['orientation', 'choice'], this.claim)
  }

  get alreadyChosenOrientation() {
    return this.orientationChoice !== null && this.orientationChoice !== ''
  }

  get isPending() {
    return path(['status', 'key'], this.claim) === 'CMPE'
  }

  get isIRSI() {
    return path(['damageDescription', 'applicableConventionType', 'id'], this.claim) === 'IRSI'
  }

  get lastBillJobs() {
    const jobs = path(['lastBillJobs'], this.claim)
    return !isNil(jobs) ? jobs : []
  }

  get hasLastBillJob() {
    return this.orientationChoice === 'ren' && this.lastBillJobs.length > 0
  }

  getLastBillJobById = id => {
    for (let i = 0; i < this.lastBillJobs.length; i++) {
      if (this.lastBillJobs[i]['id'] === id) {
        return this.lastBillJobs[i]
      }
    }

    return null
  }

  get deductibleGuarantee() {
    const deductible = path(
      ['contract', 'guarantees', 0, 'guaranteeDeductible', 'absolute', 'amount'],
      this.claim,
    )
    if (isNil(deductible)) {
      return 0
    }

    return deductible
  }

  get formulaContractKey() {
    return path(['contract', 'formulaContractKey'], this.claim)
  }

  async loadData(wan, withLoading = true) {
    if (withLoading) this.loading = true

    try {
      const claim = await fetchManagerClaim(wan)
      console.log('claim data manager: ', claim)
      await SearchFurnitureCtrl.loadFurnitureRooms(wan)
      await SearchPackageCtrl.loadMissionRooms(wan)
      await SinisterCtrl.loadData(wan, claim)
      await AddFurnitureCtrl.loadObsolescenceRate(
        path(['customer', 'id'], UserStore),
        path(['customer', 'gta'], UserStore),
        path(['contract', 'formulaContractKey'], claim),
      )
      runInAction(() => {
        this.id = wan
        CartStore.fetch(wan, claim)
        AddrelatedCostCtrl.loadRelatedCostType({
          wan,
          coverage: claim.claimInformation.coverage.key,
        })
        if (claim.claimInformation.addressOfLoss.geometry !== undefined) {
          claim.claimInformation.addressOfLoss.geometry.lat = parseFloat(
            claim.claimInformation.addressOfLoss.geometry.lat,
          )
          claim.claimInformation.addressOfLoss.geometry.long = parseFloat(
            claim.claimInformation.addressOfLoss.geometry.long,
          )
        }
        this.claim = claim
        InstructionCtrl.loadData(wan, this.claim)
        PartyInvolvedStore.loadData(claim.involvedParties)
        SupportingDocumentsCtrl.fetchSupportingDocuments(wan)
        // MessagingStore.loadData(wan, wan)
        // if (!this.isClaimClosed) CreateMissionCtrl.loadData(wan)
        CommonStore.loadSupportingDocumentsTypesData(wan, 'managerClaim')
        if (this.isWaterDamage) {
          SearchLeakCtrl.fetchLeakSearchPackage(wan)
        }
        this.loading = false
      })
    } catch (err) {
      console.log('error:', err)
      runInAction(() => {
        this.loading = true
      })
    }
  }

  async reloadClaim() {
    try {
      const claim = await fetchManagerClaim(this.id)
      runInAction(() => {
        this.claim = claim
      })
    } catch (err) {
      console.error('error:', err)
    }
  }

  async loadPendingForm(wan) {
    try {
      const form = await getPendingForm(wan)
      form.name = 'pending'
      runInAction(() => {
        this.pendingForm = new Form({ form, data: this.claim })
      })
    } catch (error) {
      console.log(error)
    }
  }

  async savePendingForm(wan, formData) {
    try {
      const claim = await savePendingForm(wan, formData)
      this.setProperty('claim', claim)
      runInAction(() => {
        this.showPendingModal = false
      })
    } catch (error) {
      console.log(error)
    }
  }

  async closeWithNoFurtherAction() {
    this.loading = true
    try {
      const claim = await closeWithNoFurtherAction(this.id)
      this.setProperty('claim', claim)
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  async closeAction() {
    this.loading = true
    try {
      const claim = await closeAction(this.id)
      this.setProperty('claim', claim)
    } catch (error) {
      console.log(error)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  async reloadStatus() {
    try {
      const claim = await fetchManagerClaim(this.id)
      runInAction(() => {
        this.claim.status = claim.status
      })
    } catch (error) {
      console.log(error)
    }
  }

  setProperty = (key, value) => {
    this[key] = value
  }

  reloadCartStore = () => {
    CartStore.fetch(this.id, this.claim)
  }
}

const DecoratedManagerClaimStore = decorate(ManagerClaimStore, {
  loading: observable,
  claim: observable,
  showPendingModal: observable,
  pendingForm: observable,
  showTeleexpertiseForm: observable,
  status: computed,
  isWaterDamage: computed,
  isClaimClosed: computed,
  isPending: computed,
  dateOfLoss: computed,
  isIRSI: computed,
  deductibleGuarantee: computed,
  formulaContractKey: computed,
  orientationChoice: computed,
  alreadyChosenOrientation: computed,
  hasLastBillJob: computed,
  lastBillJobs: computed,
  setProperty: action.bound,
  updateStatus: action.bound,
  loadData: action.bound,
  loadPendingForm: action.bound,
  savePendingForm: action.bound,
  closeWithNoFurtherAction: action.bound,
  closeAction: action.bound,
  reloadClaim: action.bound,
  reloadCartStore: action,
})

export default new DecoratedManagerClaimStore()
