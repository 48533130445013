import React, { useEffect } from 'react'
import { observer, inject } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CardLayout from 'components/CardLayout/CardLayout'
import Loader from 'components/Loader/Loader'
import Filters from './Filters'
import Event from './Event'
import LastUpdatedAt from '../../components/LastUpdatedAt'

function Events({
  UiCtrl: { isMobile },
  EventCtrl: { loading, eventsFiltered, loadData, resetFilters },
  ClaimStore,
  MissionStore,
  UserStore: { isExpert },
  lastBillEvent = false,
}) {
  const { t } = useTranslation()
  const updatedAt = isExpert ? MissionStore.updatedAt : ClaimStore.updatedAt
  const { id } = useParams()
  useEffect(() => {
    loadData(id, lastBillEvent)

    return resetFilters
  }, [id, loadData, resetFilters, lastBillEvent])

  if (loading)
    return (
      <CardLayout className="events">
        <Loader />
      </CardLayout>
    )
  return (
    <CardLayout className="events">
      <div className="row mb-3">
        <div className="col-3">{t('events.title')}</div>
        <div className="col-9 d-flex justify-content-end">
          <LastUpdatedAt lastUpdatedAt={updatedAt} saving={false} suffix={'Claim'} />
        </div>
      </div>

      <Filters />

      <div className="table-responsive">
        <table className="table table-bordered ">
          <thead>
            <tr className="bg-primary text-light">
              {!isMobile && <th>{t('events.user')}</th>}
              {!isMobile && <th>{t('events.level')}</th>}
              {!isMobile && <th>{t('events.category')}</th>}
              <th>{t('events.action')}</th>
              <th>{t('events.date')}</th>
            </tr>
          </thead>
          <tbody>
            {eventsFiltered.length > 0 ? (
              eventsFiltered.map(event => <Event key={event.id} data={event} />)
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  {t('events.none')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </CardLayout>
  )
}

export default inject('UiCtrl', 'EventCtrl', 'ClaimStore', 'MissionStore', 'UserStore')(
  observer(Events),
)
